// extracted by mini-css-extract-plugin
export const root = "project-module--root--AuvwJ";
export const title = "project-module--title--94kjD typography-module--responsiveTitle1--2aak3";
export const loaderContainer = "project-module--loaderContainer--sybY5";
export const mainImage = "project-module--mainImage--1hjKI";
export const grid = "project-module--grid--nXLdj";
export const mainContent = "project-module--mainContent--1raju";
export const metaContent = "project-module--metaContent--3wqw4";
export const publishedAt = "project-module--publishedAt--2g52T typography-module--small--2jpCI";
export const categories = "project-module--categories--1vi9F";
export const categoriesHeadline = "project-module--categoriesHeadline--25H9m typography-module--base--2DtHq";
export const relatedProjects = "project-module--relatedProjects--2spDm";
export const relatedProjectsHeadline = "project-module--relatedProjectsHeadline--1u5ce typography-module--base--2DtHq";